<template lang="pug">
div.w-100.text-left.white
  h4.text-left.pt-2 {{ $t('history') }}
  v-tabs(
    show-arrows
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline")
    v-tabs-slider(color="primary")
    div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id")
      v-tab(:to="tab.link" v-if="tab.checkAccess()").p-2 {{$t(tab.label)}}
  router-view
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
export default {
  data () {
    return {
      tabs: [
        { id: 1, link: '/history/user', label: 'userHistory', checkAccess: () => checkAccess('menuItem-userHistory') },
        { id: 2, link: '/history/crewing', label: 'crewHistory', checkAccess: () => checkAccess('menuItem-crewHistory') },
        { id: 3, link: '/history/eti', label: 'etiHistory', checkAccess: () => checkAccess('menuItem-etiHistory') }
      ]
    }
  }
}
</script>
